import { Environment, OrbitControls, useGLTF } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { Perf } from 'r3f-perf';
import { useEffect, useState } from 'react';
import { Group, Vector3 } from 'three';

import { GltfExtend, VideoConfig } from './gltf-extend';
import { GltfLoader, GltfLoaderStyle } from './gltf-loader';
import { GltfModel, GltfModelState } from './gltf-model';

type CameraSettings = {
  position?: Vector3;
  fov?: number;
};

type OrbitControlsSettings = {
  minDistance?: number;
  maxDistance?: number;
};

type GltfSceneProps = {
  canvasClassName?: string;
  model: string;
  environment: string;
  background?: boolean | 'only';
  cameraSettings: CameraSettings;
  orbitControlsSettings?: OrbitControlsSettings;
  modelState: GltfModelState;
  loaderStyle?: GltfLoaderStyle;
  videoConfig?: VideoConfig;
  onLoad?: () => void;
  showPerf?: boolean;
};

export const GltfScene = ({
  canvasClassName,
  model,
  environment,
  background = true,
  cameraSettings,
  orbitControlsSettings,
  modelState,
  loaderStyle,
  videoConfig,
  onLoad,
  showPerf = false,
}: GltfSceneProps) => {
  const [mounted, setMounted] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [modelScene, setModelScene] = useState<Group | undefined>(undefined);

  useGLTF.preload(model);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (mounted) {
      setLoaded(true);
      onLoad && onLoad();
    }
  }, [modelScene, onLoad]);

  if (!mounted) {
    return null;
  }

  return (
    <>
      <Canvas
        camera={cameraSettings}
        gl={{ logarithmicDepthBuffer: true }}
        className={canvasClassName}
      >
        {showPerf ? <Perf /> : null}
        <Environment files={environment} background={background} />
        <GltfModel
          url={model}
          ready={loaded}
          state={modelState}
          onInitScene={setModelScene}
        />
        <OrbitControls {...orbitControlsSettings} />
      </Canvas>
      <GltfExtend videoConfig={videoConfig} modelScene={modelScene} />
      <GltfLoader style={loaderStyle} />
    </>
  );
};
