import { cn, format, usePrice } from '@collection-platform-frontend/shared';
import { ShoppingCartIcon } from '@heroicons/react/24/solid';
import { FC } from 'react';

import { Image, Typography } from '../../';

type ActivityColumnProps = {
  label: string;
  className?: string;
};

export const ActivityColumn: FC<ActivityColumnProps> = ({
  className,
  label,
}) => {
  return (
    <div className={cn('flex items-center space-x-4', className)}>
      <div className="w-[40px] h-[40px] flex justify-center items-center bg-white rounded-md dark:bg-zinc-900">
        <ShoppingCartIcon className="w-5 h-5" />
      </div>
      <Typography variant="body2">{label}</Typography>
    </div>
  );
};

type PriceColumnProps = {
  amount: number;
  currencyCode: string;
  className?: string;
};

export const PriceColumn: FC<PriceColumnProps> = ({
  className,
  amount,
  currencyCode,
}) => {
  const price = usePrice(
    {
      amount,
      currencyCode,
    },
    false,
  );

  return (
    <div className={cn('flex items-center space-x-4', className)}>
      <Typography variant="body2">{price}</Typography>
    </div>
  );
};

type ImageLabelColumnProps = {
  src: string;
  label: string;
  className?: string;
};

export const ImageLabelColumn: FC<ImageLabelColumnProps> = ({
  className,
  src,
  label,
}) => {
  return (
    <div className={cn('flex items-center space-x-4', className)}>
      <Image
        src={src}
        className="w-[40px] h-[40px]"
        imageClassName="rounded-xl"
        layout="fill"
        alt="trade_nft"
      />
      <Typography variant="body2">{label}</Typography>
    </div>
  );
};

type DateColumnProps = {
  value: string;
  className?: string;
};

export const DateColumn: FC<DateColumnProps> = ({ className, value }) => {
  return (
    <div className={cn('flex items-center space-x-4', className)}>
      <Typography variant="body2">{format(value)}</Typography>
    </div>
  );
};
