import { cn } from '@collection-platform-frontend/shared';
import { Tab } from '@headlessui/react';
import { FC, PropsWithChildren, ReactNode } from 'react';

import { HList } from '../container/container';
import { Typography } from '../typography/typography';

export type TabView = {
  tabName: string;
  count?: number;
  renderer: ReactNode;
};

export type TabNavigatorProps = PropsWithChildren<{
  selectedIndex: number;
  onChangeIndex: (index: number) => void;
  views: (TabView | undefined)[];
}>;

export const TabNavigator: FC<TabNavigatorProps> = ({
  selectedIndex = 0,
  onChangeIndex,
  views,
}) => {
  const renderViews = views.filter((v) => !!v) as TabView[];

  return (
    <Tab.Group selectedIndex={selectedIndex} onChange={onChangeIndex}>
      <HList className="border-b">
        <Tab.List className="flex w-full px-4 space-x-2 sm:px-8">
          {renderViews.map((o, i) => {
            const { tabName, count } = o;
            return (
              <Tab
                key={i}
                className={cn([
                  'inline-block p-3 border-b-2 border-transparent rounded-t-lg text-wallet-light-secondary',
                  'focus-visible:outline-0',
                  {
                    'border-wallet-light-primary dark:border-wallet-primary text-wallet-light-primary dark:text-wallet-primary':
                      selectedIndex === i,
                  },
                ])}
              >
                <div className="flex items-center justify-center space-x-1">
                  <Typography>{tabName}</Typography>
                  {count && (
                    <Typography
                      variant="caption"
                      className="px-1 py-1 text-white bg-black rounded-md"
                    >
                      {count}
                    </Typography>
                  )}
                </div>
              </Tab>
            );
          })}
        </Tab.List>
      </HList>
      <Tab.Panels className="p-4 sm:p-8 min-h-[600px]">
        {views.map((o, i) => {
          if (!o) {
            return null;
          }

          const { renderer } = o;
          return <Tab.Panel key={i}>{renderer}</Tab.Panel>;
        })}
      </Tab.Panels>
    </Tab.Group>
  );
};
