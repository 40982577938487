import { FC, SVGAttributes } from 'react';

export const SwipeIcon: FC<SVGAttributes<Element>> = ({ ...props }) => {
  return (
    <svg
      width="256"
      height="256"
      viewBox="0 0 256 256"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M88.6334 151.667L72.2674 173.488C69.9808 176.536 68.832 180.288 69.0199 184.094C69.2078 187.901 70.7206 191.521 73.2964 194.329L104.199 228.041C107.286 231.414 111.647 233.333 116.229 233.333H153.967C173.567 233.333 186.633 217 186.633 200.667V130.67M162.133 135.333V130.67C162.133 112.001 186.633 112.001 186.633 130.67"
        stroke="current"
        strokeWidth="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M137.633 135.333V121.336C137.633 102.667 162.133 102.667 162.133 121.336V135.333M113.133 135.333V114.917C113.133 96.2477 137.633 96.2477 137.633 114.917V135.333M113.133 135.333V82.2418C113.131 78.9943 111.84 75.8806 109.542 73.5851C107.245 71.2895 104.131 70 100.883 70C97.6344 70 94.5186 71.2906 92.2212 73.5879C89.9239 75.8853 88.6333 79.0011 88.6333 82.25V176.167"
        stroke="current"
        strokeWidth="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M193 38H61"
        stroke="current"
        strokeWidth="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M180 22L195.5 37.5L180 53"
        stroke="current"
        strokeWidth="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.5 54L60 38.5L75.5 23"
        stroke="current"
        strokeWidth="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
