import { Typography } from '../typography/typography';

const modifieres = [
  '1.,2.,3.,4.,5.,6.,7.,8.,9.,10.,11.,12.,13.,14.,15.,16.,17.,18.,19.,20.',
  '(1),(2),(3),(4),(5),(6),(7),(8),(9),(10),(11),(12),(13),(14),(15),(16),(17),(18),(19),(20)',
  '(a),(b),(c),(d),(e),(f),(g),(h),(i),(j),(k),(l),(m),(n),(o),(p),(q),(r),(s),(t),(u),(v),(w),(x),(y),(z)',
];

type Level = 1 | 2 | 3;

export type ArticleProps = {
  className?: string;
  contents: (string | string[])[];
  level?: Level;
};

export const Article: React.FC<ArticleProps> = ({
  contents,
  className,
  level = 1,
}) => {
  let indentNo = 0;
  return (
    <>
      {contents.map((content: string | string[], i: number) => {
        if (Array.isArray(content)) {
          return (
            <div key={i} className="pl-3">
              <Article
                className={className}
                contents={content}
                level={(level + 1) as Level}
              />
            </div>
          );
        }

        const multipleContents = contents.length > 1;
        const prefixes = modifieres[level - 1].split(',');
        return (
          <div key={i} className="flex pb-6 pl-3">
            <Typography className={className} disableSkelton={true}>
              <div className="flex space-x-2">
                {multipleContents && (
                  <span className="break-keep">{prefixes[indentNo++]}</span>
                )}
                <span
                  dangerouslySetInnerHTML={{
                    __html: content,
                  }}
                ></span>
              </div>
            </Typography>
          </div>
        );
      })}
    </>
  );
};
