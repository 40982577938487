import toast from 'react-hot-toast';

import { Typography } from '..';

const info = (message?: string) => {
  if (!message) {
    return;
  }

  return toast.custom(
    <div className="px-8 py-4 border z-cover shadow-3xl rounded-2xl bg-wallet-light-base dark:bg-wallet-base border-wallet-light-primary dark:border-wallet-primary">
      <Typography className="text-wallet-light-primary dark:text-wallet-primary">
        {message}
      </Typography>
    </div>,
  );
};

const success = (message?: string) => {
  if (!message) {
    return;
  }

  return toast.custom(
    <div className="px-8 py-4 border z-cover shadow-3xl rounded-2xl bg-wallet-light-base dark:bg-wallet-base border-wallet-light-success dark:border-wallet-success">
      <Typography className="text-wallet-success">{message}</Typography>
    </div>,
  );
};

const failure = (message?: string) => {
  if (!message) {
    return;
  }

  return toast.custom(
    <div className="px-8 py-4 border-2 z-cover shadow-3xl rounded-2xl bg-wallet-light-base dark:bg-wallet-base border-wallet-light-error dark:border-wallet-error">
      <Typography className="text-wallet-error">{message}</Typography>
    </div>,
  );
};

export const useToast = () => {
  return {
    info,
    success,
    failure,
  };
};
