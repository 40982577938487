import { cn } from '@collection-platform-frontend/shared';
import {
  ChangeEvent,
  FC,
  FocusEvent,
  Fragment,
  InputHTMLAttributes,
  KeyboardEvent,
  useCallback,
  useState,
} from 'react';
import { isIOS } from 'react-device-detect';

import { Typography } from '../typography/typography';

const keyboards = {
  backspace: 8,
  enterKey: 13,
  leftArrowKey: 37,
  upArrowKey: 38,
  rightArrowKey: 39,
  downArrowKey: 40,
  eKey: 69,
};

export type CodeInputProps = InputHTMLAttributes<HTMLInputElement> & {
  fields: number;
  chars?: number;
  forceUppercase?: boolean;
  filterKeyCodes?: number[];
  filterChars?: string[];
  filterCharsIsWhitelist?: boolean;
  errorMessage?: string;
  onChangeValue?: (value: string) => void;
};

export const CodeInput: FC<CodeInputProps> = ({
  className,
  fields = 4,
  chars = 4,
  forceUppercase = false,
  filterKeyCodes = [189, 190],
  filterCharsIsWhitelist = false,
  filterChars = ['-'],
  errorMessage,
  onChangeValue,
  ...rest
}) => {
  const [inputRefs, setInputRefs] = useState<HTMLInputElement[]>([]);

  const onRef = (no: number) => {
    return (ref) => {
      inputRefs[no] = ref;
      setInputRefs(inputRefs);
    };
  };

  const onChange = useCallback(
    (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
      setTimeout(() => {
        const ne = event?.nativeEvent as InputEvent;
        let currentValue = event.target.value;
        if (
          !currentValue ||
          // iOSでinputTypeが以下の時、複数回onChangeが走るのを回避
          (isIOS &&
            (ne?.inputType === 'insertCompositionText' ||
              ne?.inputType === 'deleteCompositionText'))
        ) {
          return;
        }

        if (forceUppercase) {
          currentValue = currentValue.toUpperCase();
        }

        currentValue = currentValue
          .split('')
          .filter((currChar) => {
            if (filterCharsIsWhitelist) {
              return filterChars.includes(currChar);
            }

            return !filterChars.includes(currChar);
          })
          .join('');

        // const reg = rest?.pattern && new RegExp(rest.pattern);
        // if (reg && !reg.test(currentValue)) {
        //   inputRefs[index].value = '';
        //   return;
        // }

        let nextIndex = index < inputRefs.length - 1 ? index + 1 : index;
        if (currentValue.length > chars) {
          const values = currentValue
            .split('')
            .reduce<string[][]>((resultArray, item, index) => {
              const chunkIndex = Math.floor(index / chars);

              if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = [];
              }

              resultArray[chunkIndex].push(item);

              return resultArray;
            }, [])
            .map((v) => {
              return v.join('');
            }, []);

          for (let i = 0; i < values.length; i++) {
            const currentIndex = i + index;
            const value = values[i];
            if (currentIndex < fields) {
              inputRefs[currentIndex].value = value;
              nextIndex =
                currentIndex < inputRefs.length - 1 && value.length === chars
                  ? currentIndex + 1
                  : currentIndex;
            }
          }
        } else {
          inputRefs[index].value = currentValue;
        }

        const filled = inputRefs[index].value.length === chars;
        const newTarget = inputRefs[nextIndex];
        if (filled && newTarget) {
          newTarget.focus();
          // newTarget.select();
        }

        if (onChangeValue) {
          const newValue = inputRefs
            .map((r) => {
              return r?.value ?? '';
            })
            .join('');

          onChangeValue(newValue);
        }
      }, 100);
    },
    [
      chars,
      fields,
      filterChars,
      filterCharsIsWhitelist,
      forceUppercase,
      inputRefs,
      onChangeValue,
      // rest,
    ],
  );

  const onKeyDown = useCallback(
    (index: number) => (event: KeyboardEvent<HTMLInputElement>) => {
      const target = event.currentTarget;
      const nextTarget = inputRefs[index + 1];
      const prevTarget = inputRefs[index - 1];

      if (filterKeyCodes.length > 0) {
        filterKeyCodes.forEach((code) => {
          if (code === event.keyCode) {
            event.preventDefault();
            return true;
          }
        });
      }

      const input = target.value.split('');
      switch (event.keyCode) {
        case keyboards.backspace:
          if (target.value === '') {
            event.preventDefault();

            if (prevTarget) {
              prevTarget.focus();
            }
          }

          if (target.value.length === 1) {
            target.value = '';
            input[index] = '';
          }

          if (onChangeValue) {
            onChangeValue(input.join(''));
          }
          break;

        case keyboards.leftArrowKey:
          event.preventDefault();
          if (prevTarget) {
            prevTarget.focus();
            prevTarget.select();
          }
          break;

        case keyboards.rightArrowKey:
        case keyboards.enterKey:
          event.preventDefault();
          if (nextTarget) {
            nextTarget.focus();
            nextTarget.select();
          }
          break;

        case keyboards.upArrowKey:
          event.preventDefault();
          break;

        case keyboards.downArrowKey:
          event.preventDefault();
          break;

        default:
          break;
      }
    },
    [filterKeyCodes, inputRefs, onChangeValue],
  );

  const onFocus = useCallback((event: FocusEvent<HTMLInputElement>) => {
    // event.target.select();
    event.preventDefault();
  }, []);

  const onBlur = useCallback((event: FocusEvent<HTMLInputElement>) => {
    event.preventDefault();
    // event.target.select();
  }, []);

  const range = new Array(fields).fill(0);
  return (
    <div className={'flex flex-col'}>
      <div className={cn(className, 'flex items-center justify-center py-2')}>
        {range.map((_num, i) => {
          return (
            <Fragment key={i}>
              {i !== 0 && (
                <div className="px-1 text-center text-wallet-light-inactive">
                  -
                </div>
              )}
              <input
                ref={onRef(i)}
                className={cn(
                  'bg-wallet-light-base border-wallet-primary p-2 box-border border text-center text-base rounded-md focus:shadow-none outline-none',
                  !!errorMessage && 'border-wallet-light-error',
                )}
                {...rest}
                style={{ ...(rest?.style ?? {}), width: 17 * fields + 'px' }}
                onContextMenu={(e) => e.preventDefault()}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={onChange(i)}
                onKeyDown={onKeyDown(i)}
              />
            </Fragment>
          );
        })}
      </div>
      {errorMessage && (
        <Typography
          variant="caption"
          className={cn('pt-1 text-wallet-light-error')}
        >
          {errorMessage}
        </Typography>
      )}
    </div>
  );
};
