import { cn } from '@collection-platform-frontend/shared';
import {
  DetailedHTMLProps,
  FC,
  forwardRef,
  HTMLAttributes,
  PropsWithChildren,
  ReactNode,
  TdHTMLAttributes,
} from 'react';

import { NotFoundItem, Typography } from '../';

type Props<T> = { children: ReactNode } & HTMLAttributes<T>;

export const Table = forwardRef<HTMLTableElement, Props<HTMLTableElement>>(
  (props, ref) => {
    return (
      <table
        ref={ref}
        {...props}
        className={cn(
          'min-w-full divide-y divide-zinc-200 dark:divide-zinc-700/20 ',
          props.className,
        )}
      >
        {props.children}
      </table>
    );
  },
);
Table.displayName = 'Table';

export const Thead = forwardRef<
  HTMLTableSectionElement,
  Props<HTMLTableSectionElement>
>((props, ref) => {
  return (
    <thead
      ref={ref}
      {...props}
      className={cn('bg-zinc-100 dark:bg-zinc-800', props.className)}
    >
      {props.children}
    </thead>
  );
});
Thead.displayName = 'Thead';

export const Tr = forwardRef<HTMLTableRowElement, Props<HTMLTableRowElement>>(
  (props, ref) => (
    <tr ref={ref} {...props}>
      {props.children}
    </tr>
  ),
);
Tr.displayName = 'Tr';

export const Th = forwardRef<HTMLTableCellElement, Props<HTMLTableCellElement>>(
  (props, ref) => (
    <th
      ref={ref}
      scope="col"
      {...props}
      className={cn('px-6 py-3 text-left  uppercase', props.className)}
    >
      {props.children}
    </th>
  ),
);
Th.displayName = 'Th';

export const Tbody = forwardRef<
  HTMLTableSectionElement,
  Props<HTMLTableSectionElement>
>((props, ref) => (
  <tbody ref={ref} {...props} className={props.className}>
    {props.children}
  </tbody>
));
Tbody.displayName = 'Tbody';

export const Td = forwardRef<
  HTMLTableDataCellElement,
  DetailedHTMLProps<
    TdHTMLAttributes<HTMLTableDataCellElement>,
    HTMLTableDataCellElement
  >
>((props, ref) => (
  <td
    ref={ref}
    {...props}
    className={cn(
      'px-6 py-4 whitespace-nowrap table-cell align-middle',
      props.className,
    )}
  >
    {props.children}
  </td>
));
Td.displayName = 'Td';

export type FlexRow = Record<string, string | number>;

export type FlexColumn = {
  key: string;
  label: string;
  className?: string;
  Renderer?: (data: Record<string, string | number>, key: string) => ReactNode;
};

export type FlexTablePros = {
  className?: string;
  columns: FlexColumn[];
  rows?: FlexRow[] | null;
  labels: {
    noListing: string;
  };
};

export const FlexTable: FC<PropsWithChildren<FlexTablePros>> = ({
  className,
  columns,
  rows,
  labels,
}) => {
  return (
    <div
      className={cn(
        'w-full overflow-x-auto border rounded-xl bg-zinc-100 dark:bg-zinc-800 border-zinc-200 dark:border-zinc-700/40',
        className,
      )}
    >
      <Table>
        <Thead>
          <Tr>
            {columns.map(({ label }, i) => {
              return (
                <Th key={i}>
                  <Typography variant="h4" className="uppercase">
                    {label}
                  </Typography>
                </Th>
              );
            })}
          </Tr>
        </Thead>
        <Tbody className="min-h-full overflow-y-auto">
          {rows && rows.length > 0 ? (
            rows.map((v, i) => {
              return (
                <Tr key={i}>
                  {columns.map(({ key, className, Renderer }, j) => {
                    return (
                      <Td key={j} className={className}>
                        {Renderer ? (
                          Renderer(v, key)
                        ) : (
                          <Typography
                            variant="h4"
                            className="w-[140px] truncate"
                          >
                            {v[key]}
                          </Typography>
                        )}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })
          ) : (
            <Tr>
              <Td colSpan={columns.length} className={className}>
                <div className="flex justify-center w-full">
                  <div className="flex flex-col items-center justify-center py-32 space-y-2 text-center w-fit">
                    <NotFoundItem />
                    <Typography variant="h4">{labels.noListing}</Typography>
                  </div>
                </div>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </div>
  );
};

export * from './column/column';
