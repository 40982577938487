import { cn } from '@collection-platform-frontend/shared';
import { Padding, Placement } from '@popperjs/core';
import Tippy from '@tippyjs/react/headless';
import { FC, ReactNode, useState } from 'react';

type Theme = {
  borderColor: string;
  backgroundColor: string;
};

export type HintProps = {
  target: ReactNode;
  placement?: Placement;
  theme?: Theme;
  padding?: Padding;
  trigger?: 'click' | 'focus' | 'mouseenter' | 'manual';
  disabled?: boolean;
  useBalloonStyle?: boolean;
  children?: ReactNode;
};

export const Hint: FC<HintProps> = ({
  placement = 'auto',
  target,
  theme = {
    borderColor: 'border-wallet-light-container dark:border-wallet-container',
    backgroundColor: 'bg-wallet-light-container',
  },
  padding = 10,
  trigger = 'mouseenter',
  disabled = false,
  useBalloonStyle = true,
  children,
}) => {
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);

  return (
    <Tippy
      trigger={trigger}
      disabled={disabled}
      zIndex={2}
      render={(attrs) => {
        return (
          <div {...attrs} className="relative text-wallet-light-primary ">
            <div
              className={cn(
                'relative min-w-[200px] rounded-3xl shadow-3xl　dark:shadow-none',
                { ' p-4 mx-4 ': useBalloonStyle },
                theme.backgroundColor,
                theme.borderColor,
              )}
            >
              {children}
            </div>
            <div
              ref={setArrowElement}
              className={cn(
                'absolute hidden',
                { block: useBalloonStyle },
                attrs['data-placement'] === 'top' && 'bottom-[-4px]',
                attrs['data-placement'] === 'bottom' && 'top-[-4px]',
              )}
            >
              <div
                className={cn(
                  'w-2 h-2 rotate-45',
                  theme.backgroundColor,
                  theme.borderColor,
                )}
              />
            </div>
          </div>
        );
      }}
      popperOptions={{
        modifiers: [
          {
            name: 'arrow',
            options: {
              element: arrowElement,
              padding,
            },
          },
        ],
        placement,
      }}
    >
      <button>{target}</button>
    </Tippy>
  );
};
