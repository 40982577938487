import { Menu, Transition } from '@headlessui/react';
import { FC, Fragment, ReactNode } from 'react';

export type MenuButtonProps = {
  children?: ReactNode;
  items?: ReactNode | null | undefined;
};

export const MenuButton: FC<MenuButtonProps> = ({ items, children }) => {
  return (
    <Menu
      as="div"
      className="relative inline-block !outline-none !ring-transparent"
    >
      <Menu.Button>{children}</Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items>{items}</Menu.Items>
      </Transition>
    </Menu>
  );
};
