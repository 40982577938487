import { cn } from '@collection-platform-frontend/shared';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid';
import { FC, Fragment } from 'react';

import { Typography } from '..';

type Item = {
  label: string;
  value: string;
};

export type DropDownProps = {
  selectedItem: Item;
  items: Item[];
  onChange?: (item: Item) => void;
};

export const DropDown: FC<DropDownProps> = ({
  items,
  selectedItem,
  onChange = () => undefined,
}) => {
  return (
    <Listbox value={selectedItem} onChange={onChange}>
      <div className="relative mt-1">
        <Listbox.Button className="relative w-full py-4 pl-3 pr-10 text-left transition-colors rounded-lg cursor-pointer bg-zinc-100 dark:bg-zinc-800 hover:bg-zinc-200 dark:hover:bg-zinc-700 text-zinc-800">
          <Typography variant="h4" className="block truncate">
            {selectedItem.label}
          </Typography>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <ChevronUpDownIcon
              className="w-5 h-5 text-zinc-800 dark:text-zinc-100"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base rounded-md shadow-lg bg-zinc-100 z-cover dark:bg-zinc-800 max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {items.map((o, i) => (
              <Listbox.Option
                key={i}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active
                      ? 'bg-zinc-200 text-zinc-800 dark:bg-zinc-700 dark:text-zinc-100'
                      : 'text-zinc-700 dark:text-zinc-200'
                  }`
                }
                value={o}
              >
                {({ selected }) => (
                  <>
                    <Typography
                      variant="body2"
                      className={cn(
                        'block truncate text-zinc-800 dark:text-zinc-100',
                        {
                          '!font-semibold': selected,
                        },
                      )}
                    >
                      {o.label}
                    </Typography>
                    {selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-zinc-800 dark:text-zinc-100">
                        <CheckIcon className="w-5 h-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};
