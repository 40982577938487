import { cn } from '@collection-platform-frontend/shared';
import { Menu } from '@headlessui/react';
import { UserCircleIcon } from '@heroicons/react/24/outline';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import NextImage from 'next/legacy/image';
import { FC } from 'react';

import { Image, MenuButton, Typography, WalletUser } from '..';

export type MenuLink = {
  label: string;
  href: string;
};

export type UserMenuProps = {
  user?: WalletUser;
  menus?: MenuLink[];
  onLink?: (itemKey: string) => void;
  onLogin?: () => void;
  onLogout?: () => void;
};

export const UserMenu: FC<UserMenuProps> = ({
  user,
  menus,
  onLink,
  onLogin,
  onLogout,
}) => {
  return (
    <div className="absolute right-0 min-w-[240px] p-3 px-6 mt-2 origin-top-right dark:bg-wallet-container bg-wallet-light-container dark:shadow-[#111111] divide-y divide-wallet-light-inactive dark:divide-wallet-inactive rounded-3xl shadow-3xl focus-within:outline-none z-cover">
      {user && (
        <div className="py-3 select-none">
          <div key="userInfo" className="flex items-center pb-2">
            {user?.profileIconUrl && (
              <div className="w-8 h-8">
                <NextImage
                  className="rounded-[50%]"
                  src={user.profileIconUrl}
                  width={100}
                  height={100}
                />
              </div>
            )}
            <div className="flex flex-col pl-3">
              <Typography variant="body">{user?.nickName}</Typography>
              <Typography
                className="truncate text-wallet-gray-caption"
                variant="body"
              >
                {user?.email}
              </Typography>
            </div>
          </div>
        </div>
      )}
      {user && menus && (
        <div className="py-3">
          {menus.map(({ label, href }, i) => {
            const isPath = href?.startsWith('/');
            return (
              <Menu.Item key={i}>
                {({ active }) => (
                  <div
                    className={cn(
                      'flex items-center space-x-1 p-3 text-sm cursor-pointer',
                      {
                        'bg-gray-100 dark:bg-gray-900 rounded-xl': active,
                      },
                    )}
                    onClick={() => onLink && onLink(href)}
                  >
                    <Typography variant="body">{label}</Typography>
                    {!isPath && (
                      <ArrowTopRightOnSquareIcon className="w-6 h-6" />
                    )}
                  </div>
                )}
              </Menu.Item>
            );
          })}
        </div>
      )}
      <div className="py-3">
        <Menu.Item key="logout">
          {({ active }) => (
            <div
              className={cn('block p-3 text-sm rounded-xl cursor-pointer', {
                'bg-gray-100 dark:bg-gray-900': active,
              })}
              onClick={user ? onLogout : onLogin}
            >
              <Typography variant="body">
                {user ? 'ログアウト' : 'ログイン'}
              </Typography>
            </div>
          )}
        </Menu.Item>
      </div>
    </div>
  );
};

type UserMenuButton = {
  user?: WalletUser;
  menus?: MenuLink[];
  onLink?: (href: string) => void;
  onLogin?: () => void;
  onLogout?: () => void;
};

export const UserMenuButton: FC<UserMenuButton> = ({
  user,
  menus,
  onLink,
  onLogin,
  onLogout,
}) => {
  return (
    <MenuButton
      items={
        <UserMenu
          user={user}
          menus={menus}
          onLink={onLink}
          onLogin={onLogin}
          onLogout={onLogout}
        />
      }
    >
      <div className="flex items-center ring-0">
        {user ? (
          <Image
            className="w-10 h-10"
            imageClassName="rounded-full"
            skeletonClassName="rounded-full"
            layout="fill"
            src={user.profileIconUrl}
            alt="profile"
          />
        ) : onLogin ? (
          <UserCircleIcon className="w-10 h-10" />
        ) : null}
      </div>
    </MenuButton>
  );
};
