import { cn } from '@collection-platform-frontend/shared';
import { FC, PropsWithChildren } from 'react';

export type SkeletonProps = {
  className?: string;
  skeleton?: boolean;
  skeletonClassName?: string;
};

export const Skeleton: FC<PropsWithChildren<SkeletonProps>> = ({
  className,
  children,
  skeleton,
  skeletonClassName,
}) => {
  return (
    <>
      <div
        className={cn(
          'transition-opacity duration-500',
          {
            'opacity-0': skeleton,
            'opacity-100': !skeleton,
          },
          className,
        )}
      >
        {children}
      </div>
      <div
        className={cn(
          'absolute top-0 left-0 right-0 bottom-0 transition-opacity duration-500',
          {
            'opacity-0 pointer-events-none': !skeleton,
            'opacity-100': skeleton,
          },
        )}
      >
        <div
          className={cn(
            'w-full h-full bg-[#E6E6F2] dark:bg-[#2A2B2F] animate-pulse rounded-lg',
            skeletonClassName,
          )}
        />
      </div>
    </>
  );
};
