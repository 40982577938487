import { FC } from 'react';

import { Typography } from '../';
import { Modal } from './modal';

export type ConfirmLabels = {
  title: string;
  message: string;
  resetLabel: string;
  caption?: string;
};

export type ConfirmModalProps = {
  show: boolean;
  labels: ConfirmLabels;
  onReset?: () => void;
};

export const ConfirmModal: FC<ConfirmModalProps> = ({
  show,
  labels,
  onReset,
}) => {
  return (
    <Modal show={show}>
      <div className="w-full p-6 overflow-hidden text-center transition-all transform shadow-xl max-w-[330px] bg-wallet-light-base dark:bg-wallet-base text-wallet-light-primary dark:text-wallet-primary rounded-3xl">
        <Typography variant="h2" className="pb-8">
          {labels.title}
        </Typography>
        <div className="pb-10 text-center ">
          <Typography>
            <span dangerouslySetInnerHTML={{ __html: labels.message }} />
          </Typography>
          {labels.caption && (
            <Typography
              variant="caption"
              disableSkelton={true}
              className="px-6 pt-2 text-wallet-thirdly"
            >
              <span dangerouslySetInnerHTML={{ __html: labels.caption }} />
            </Typography>
          )}
        </div>
        <div onClick={onReset}>
          <Typography
            className="px-6 py-2 cursor-pointer text-wallet-thirdly"
            disableSkelton={true}
          >
            {labels.resetLabel}
          </Typography>
        </div>
      </div>
    </Modal>
  );
};
