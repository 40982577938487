import { cn } from '@collection-platform-frontend/shared';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import { FC, PropsWithChildren, useCallback, useState } from 'react';

import { Typography } from '..';
type Props = PropsWithChildren<{
  className?: string;
  title: string;
  initOpen?: boolean;
}>;

export const Accordion: FC<Props> = ({
  className,
  title,
  initOpen = false,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(initOpen);

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);
  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <div
      className={cn(
        'p-8 border rounded-3xl w-full border-wallet-light-inactive dark:border-wallet-inactive',
        className,
      )}
    >
      <div className="flex flex-row items-center justify-between gap-x-1">
        <Typography variant="body" className="text-wallet-secondary">
          {title}
        </Typography>

        {isOpen ? (
          <MinusIcon
            className="w-5 h-5 cursor-pointer text-wallet-secondary"
            aria-hidden="true"
            onClick={close}
          />
        ) : (
          <PlusIcon
            className="w-5 h-5 cursor-pointer text-wallet-secondary"
            aria-hidden="true"
            onClick={open}
          />
        )}
      </div>

      {isOpen && children}
    </div>
  );
};

export default Accordion;
