import { FC, SVGAttributes } from 'react';

export const TwitterLogo: FC<SVGAttributes<HTMLOrSVGElement>> = (props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.29 20C15.837 20 19.965 13.4598 19.965 7.78871C19.965 7.60253 19.965 7.4174 19.953 7.23436C20.7562 6.62618 21.4493 5.8732 22 5.0107C21.2511 5.35796 20.4566 5.58572 19.643 5.68638C20.4996 5.14988 21.1408 4.30611 21.447 3.31211C20.6417 3.81194 19.7607 4.16426 18.842 4.35386C18.2234 3.66539 17.405 3.20943 16.5136 3.05659C15.6222 2.90375 14.7075 3.06254 13.9111 3.50839C13.1147 3.95424 12.4811 4.66228 12.1083 5.52286C11.7355 6.38344 11.6444 7.34856 11.849 8.26879C10.2176 8.18328 8.6216 7.73989 7.16465 6.96741C5.70769 6.19492 4.42233 5.11062 3.392 3.78487C2.86732 4.72956 2.70659 5.84789 2.94254 6.91218C3.17848 7.97646 3.79337 8.90668 4.662 9.51345C4.00926 9.49369 3.37063 9.31002 2.8 8.97794V9.03232C2.80039 10.0231 3.1284 10.9833 3.7284 11.75C4.3284 12.5168 5.16347 13.0428 6.092 13.2391C5.48781 13.4114 4.85389 13.4364 4.239 13.3123C4.50116 14.1652 5.01168 14.9111 5.69913 15.4456C6.38658 15.9801 7.21657 16.2764 8.073 16.2932C7.22212 16.9925 6.24779 17.5095 5.20573 17.8146C4.16367 18.1197 3.07432 18.2069 2 18.0713C3.8766 19.331 6.06019 19.9991 8.29 19.9958"
        fill="#1D9BF0"
      />
    </svg>
  );
};
