import { cn } from '@collection-platform-frontend/shared';
import { FC, PropsWithChildren } from 'react';

import { Typography } from '../typography/typography';

export type SectionProps = { title?: string; classname?: string };

export const Section: FC<PropsWithChildren<SectionProps>> = ({
  title,
  classname,
  children,
}) => {
  return (
    <div className={cn('w-full max-w-7xl mx-auto', classname)}>
      {title && (
        <div className="flex mb-6">
          <span className="w-2 h-auto mr-2 rounded-full bg-cloudear-blue" />
          <Typography variant="h2">{title}</Typography>
        </div>
      )}
      {children}
    </div>
  );
};
