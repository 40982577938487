import { cn } from '@collection-platform-frontend/shared';
import { Menu } from '@headlessui/react';
import { ShareIcon } from '@heroicons/react/24/outline';
import { FC, ReactNode } from 'react';

import { MenuButton, Typography } from '..';

type ActionMenu = { label: string; value: string; icon: ReactNode };

export type ActionMenuProps = {
  menus?: ActionMenu[];
  onAction?: (value: string) => void;
};

export const ActionMenu: FC<ActionMenuProps> = ({ menus, onAction }) => {
  return (
    <div className="absolute right-0 min-w-[240px] p-3 px-6 mt-2 origin-top-right dark:bg-wallet-container bg-wallet-light-container dark:shadow-[#111111] divide-y divide-wallet-light-inactive dark:divide-wallet-inactive rounded-3xl shadow-3xl focus-within:outline-none z-cover">
      {menus && (
        <div className="py-3">
          {menus.map(({ label, value, icon }, i) => {
            return (
              <Menu.Item key={i}>
                {({ active }) => (
                  <div
                    className={cn(
                      'flex items-center space-x-1 p-3 text-sm cursor-pointer',
                      {
                        'bg-gray-100 dark:bg-gray-900 rounded-xl': active,
                      },
                    )}
                    onClick={() => onAction && onAction(value)}
                  >
                    {icon}
                    <Typography variant="body">{label}</Typography>
                  </div>
                )}
              </Menu.Item>
            );
          })}
        </div>
      )}
    </div>
  );
};

type ActionMenuButtonProps = {
  menus?: ActionMenu[];
  onAction?: (value: string) => void;
};

export const ActionMenuButton: FC<ActionMenuButtonProps> = ({
  menus,
  onAction,
}) => {
  return (
    <MenuButton items={<ActionMenu menus={menus} onAction={onAction} />}>
      <ShareIcon className="w-6 h-6" />
    </MenuButton>
  );
};
