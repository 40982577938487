import { cn } from '@collection-platform-frontend/shared';
import React, { CSSProperties, ReactNode } from 'react';
import ReactMarkdown, { Options } from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

import { Skeleton, SkeletonProps } from '../skeleton/skeleton';
import s from './typography.module.css';

export type TextVariant =
  | 'body'
  | 'body2'
  | 'caption'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4';

const componentsMap: {
  [P in TextVariant]: React.ComponentType<HTMLHtmlElement> | string;
} = {
  body: 'div',
  body2: 'div',
  caption: 'div',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
};

const reactMarkdownCustomComponents: Options['components'] = {
  a: ({ node, ...props }) => (
    <a {...props} className={cn('underline', props.className)}>
      {props.children}
    </a>
  ),
};

export type TypographyProps = SkeletonProps & {
  variant?: TextVariant;
  className?: string;
  style?: CSSProperties;
  disableSkelton?: boolean;
} & (
    | {
        markdown: true;
        children: string;
      }
    | {
        markdown?: false;
        children: ReactNode;
      }
  );

export const Typography: React.FC<TypographyProps> = ({
  style,
  className,
  variant = 'body',
  children,
  markdown,
  skeleton,
  skeletonClassName,
  disableSkelton,
}) => {
  const Component: React.ComponentType<any> | string = componentsMap![variant!];

  const childrenNode = markdown ? (
    <ReactMarkdown
      children={children}
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      components={reactMarkdownCustomComponents}
    />
  ) : (
    children
  );

  return (
    <Component className={cn(s[variant], 'relative')} style={style}>
      {childrenNode && disableSkelton ? (
        <div className={className}>{childrenNode}</div>
      ) : (
        <Skeleton
          className={className}
          skeleton={skeleton}
          skeletonClassName={skeletonClassName}
        >
          {childrenNode}
        </Skeleton>
      )}
    </Component>
  );
};
