import { cn } from '@collection-platform-frontend/shared';
import { Switch } from '@headlessui/react';
import { MoonIcon, SunIcon } from '@heroicons/react/24/outline';
import { FC, ReactNode, useCallback } from 'react';

export type ToggleProps = {
  offIcon?: ReactNode;
  onIcon?: ReactNode;
  value?: boolean;
  onChange?: (value: boolean) => void;
};

export const Toggle: FC<ToggleProps> = ({
  value = false,
  offIcon,
  onIcon,
  onChange,
}) => {
  const _onChange = useCallback(
    (value: boolean) => {
      onChange && onChange(value);
    },
    [onChange],
  );

  return (
    <Switch
      checked={value}
      onChange={_onChange}
      className={cn(
        value ? 'bg-white' : 'bg-black',
        ' relative inline-flex flex-shrink-0 h-9 w-[68px] border-2 border-transparent rounded-full cursor-pointer ease-in-out duration-200 focus:outline-none focus:ring-0',
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        className={cn(
          value ? 'translate-x-0 bg-black' : 'translate-x-8 bg-white',
          'pointer-events-none relative inline-block h-8 w-8 rounded-full shadow transform ring-0 transition ease-in-out duration-200',
        )}
      />
      <span
        className={cn(
          value
            ? 'opacity-0 ease-out duration-100'
            : 'opacity-100 ease-in duration-200',
          'absolute inset-0 h-full w-full flex items-center transition-opacity translate-x-1 text-white',
        )}
        aria-hidden="true"
      >
        {offIcon}
      </span>
      <span
        className={cn(
          value
            ? 'opacity-100 ease-in duration-200'
            : 'opacity-0 ease-out duration-100',
          'absolute inset-0 h-full w-full flex items-center transition-opacity translate-x-9 text-black',
        )}
        aria-hidden="true"
      >
        {onIcon}
      </span>
    </Switch>
  );
};

export type DarkModeToggleProps = {
  toggle?: boolean;
  onChange?: (value: boolean) => void;
};

export const DarkModeToggle: FC<DarkModeToggleProps> = ({
  toggle,
  onChange,
}) => {
  return (
    <Toggle
      value={toggle}
      onChange={onChange}
      offIcon={<SunIcon className="w-6 h-6" />}
      onIcon={<MoonIcon className="w-6 h-6" />}
    />
  );
};
