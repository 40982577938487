import { cn } from '@collection-platform-frontend/shared';
import { FC, HTMLAttributes, PropsWithChildren } from 'react';

import s from './container.module.css';

export type ContainerProps = HTMLAttributes<HTMLDivElement> & {
  containerClassName?: string;
  backgroundShadow?: boolean;
};

export const Container: FC<ContainerProps> = ({
  children,
  className,
  containerClassName,
  backgroundShadow,
}) => {
  return (
    <div
      className={cn(
        'flex flex-col items-center w-screen sm:py-6 py-0 h-full sm:h-auto',
        containerClassName,
      )}
    >
      <div
        className={cn(
          'max-w-[358px] w-full bg-opacity-0 sm:bg-opacity-100 h-full',
          {
            'min-h-[300px] sm:rounded-3xl sm:shadow-3xl bg-wallet-light-container dark:bg-wallet-container dark:sm:shadow-[#111111]':
              backgroundShadow,
          },
          className,
        )}
      >
        {children}
      </div>
    </div>
  );
};

export const HList: FC<
  PropsWithChildren<{
    className?: string;
  }>
> = ({ className, children }) => {
  return (
    <div className={cn('overflow-auto whitespace-nowrap', s['scrollbar-hide'])}>
      <div className={cn('inline-flex space-x-2 min-w-full', className)}>
        {children}
      </div>
    </div>
  );
};

export const Screen: FC<
  PropsWithChildren<{
    className?: string;
  }>
> = ({ className, children }) => {
  return (
    <div className={cn('w-screen px-6 my-[0] mx-[calc(50%-50vw)]', className)}>
      {children}
    </div>
  );
};
