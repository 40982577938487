// see: https://github.com/pmndrs/drei/blob/a4a6ef3fe310cdd8e117e56b155f72ddbc1968ff/src/web/Loader.tsx#L4-L11
type LoaderStyle = {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  containerStyles?: any;
  innerStyles?: any;
  barStyles?: any;
  dataStyles?: any;
  /* eslint-enable @typescript-eslint/no-explicit-any */
  dataInterpolation?: (p: number) => string;
  initialState?: (active: boolean) => boolean;
};

const defaultLoaderStyle: LoaderStyle = {
  // 背景
  containerStyles: {
    background: 'white',
  },
  // バーの幅、バー背景
  innerStyles: {
    width: '300px',
    background: '#B3B3B3',
    textAlign: 'start',
  },
  // バーの色
  barStyles: {
    background: 'black',
  },
  // テキスト
  dataStyles: {
    fontWeight: 700,
    color: 'black',
  },
  dataInterpolation: (p) => `Loading ${p.toFixed(2)}%`,
};

export const gltfLoaderStyles = {
  default: defaultLoaderStyle,
};

export type GltfLoaderStyle = keyof typeof gltfLoaderStyles;
