import { FC, SVGAttributes } from 'react';

export const NotFoundItem: FC<SVGAttributes<Element>> = ({ ...props }) => {
  return (
    <svg width="149" height="94" viewBox="0 0 149 94" fill="none" {...props}>
      <rect
        x="0.5"
        y="0.5"
        width="148"
        height="93"
        rx="7.5"
        className="fill-white dark:fill-zinc-700 stroke-gray-200 dark:stroke-gray-500"
      />
      <rect
        x="33"
        y="17"
        width="105"
        height="12"
        rx="6"
        className="fill-gray-200 dark:fill-zinc-700"
      />
      <rect
        x="11"
        y="17"
        width="12"
        height="12"
        rx="6"
        className="fill-gray-200 dark:fill-zinc-700"
      />
      <rect
        x="33"
        y="33"
        width="105"
        height="12"
        rx="6"
        className="fill-gray-500"
      />
      <rect
        x="11"
        y="33"
        width="12"
        height="12"
        rx="6"
        className="fill-gray-500"
      />
      <rect
        x="33"
        y="49"
        width="105"
        height="12"
        rx="6"
        className="fill-gray-200 dark:fill-zinc-700"
      />
      <rect
        x="11"
        y="49"
        width="12"
        height="12"
        rx="6"
        className="fill-gray-200 dark:fill-zinc-700"
      />
      <rect
        x="33"
        y="65"
        width="105"
        height="12"
        rx="6"
        className="fill-gray-500"
      />
      <rect
        x="11"
        y="65"
        width="12"
        height="12"
        rx="6"
        className="fill-gray-500"
      />
    </svg>
  );
};
