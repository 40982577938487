import { cn } from '@collection-platform-frontend/shared';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { FC, HTMLAttributes } from 'react';

import { Typography } from '../typography/typography';

export type BackButtonProps = HTMLAttributes<HTMLDivElement> & {
  //
};

export const BackButton: FC<BackButtonProps> = ({ className, ...rest }) => {
  return (
    <div
      className={cn(
        'flex items-center w-[80px] h-[44px] cursor-pointer gap-x-2',
        className,
      )}
      {...rest}
    >
      <ArrowLeftIcon className="w-6 h-6" />
      <Typography variant="h3">もどる</Typography>
    </div>
  );
};
