import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

export type ProgressBarProps = {
  minPx?: number;
  progress: number;
  total: number;
  color?: string;
  backgroundColor?: string;
  className?: string;
};

export const ProgressBar: FC<ProgressBarProps> = ({
  minPx = 0,
  progress,
  total,
  color = 'blue',
  backgroundColor = 'gray',
  className,
}) => {
  const value = total !== 0 ? (progress / total) * 100 : 100;

  const containerClasses = 'h-2 w-full rounded-full';
  const barClasses = 'h-2 rounded-full';

  return (
    <div
      className={twMerge(containerClasses, className)}
      style={{ backgroundColor }}
    >
      <div
        className={barClasses}
        style={{
          width: `${value}%`,
          minWidth: `${minPx}px`,
          backgroundColor: color,
        }}
      />
    </div>
  );
};
