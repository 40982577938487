import { Loader } from '@react-three/drei';

import { GltfLoaderStyle, gltfLoaderStyles } from './gltf-loader-styles';

type GltfLoaderProps = {
  style?: GltfLoaderStyle;
};

/** MEMO: useGLTF.preloadと共に使用する */
export const GltfLoader = ({ style = 'default' }: GltfLoaderProps) => {
  return <Loader {...gltfLoaderStyles[style]} />;
};

export type { GltfLoaderStyle };
