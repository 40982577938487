import { cn } from '@collection-platform-frontend/shared';
import { AuthUserContext } from 'next-firebase-auth';
import { FC, JSXElementConstructor, ReactNode } from 'react';

import { WalletUser } from '../type';

export type BaseLayoutProps = {
  className?: string;
  headerContent?: ReactNode;
  mainContent: ReactNode;
  footerContent?: ReactNode;
};

export const BaseLayout: FC<BaseLayoutProps> = ({
  className,
  mainContent,
  headerContent,
  footerContent,
}) => {
  return (
    <div className={cn('min-h-screen w-full', className)}>
      <div className="flex flex-col min-h-screen">
        <header className="z-cover shrink-0">{headerContent}</header>
        <main className="flex w-full h-full grow">{mainContent}</main>
        <footer className="shrink-0">{footerContent}</footer>
      </div>
    </div>
  );
};

export type IPageLayoutProps = {
  authUser?: AuthUserContext;
  user?: WalletUser;
  children?: ReactNode;
};

export type IPageLayout = JSXElementConstructor<IPageLayoutProps>;
