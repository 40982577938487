import { useGLTF } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { Suspense, useEffect, useMemo, useState } from 'react';
import {
  AnimationActionLoopStyles,
  AnimationMixer,
  Euler,
  Group,
  LoopRepeat,
  Vector3,
} from 'three';

export type GltfModelState = {
  scale?: Vector3;
  position?: Vector3;
  rotation?: Euler;
};

type GltfModelProps = {
  url: string;
  ready: boolean;
  state: GltfModelState;
  loop?: AnimationActionLoopStyles;
  onInitScene?: (scene: Group) => void;
};

export const GltfModel = ({
  url,
  ready,
  state,
  loop = LoopRepeat,
  onInitScene,
}: GltfModelProps) => {
  const [started, setStarted] = useState<boolean>(false);

  const { scene, animations } = useGLTF(url);
  const mixer = useMemo(() => new AnimationMixer(scene), [scene]);

  useEffect(() => {
    animations.forEach((clip) => {
      const action = mixer.clipAction(clip);
      action.loop = loop;
      action.clampWhenFinished = true;
      action.play();
    });
  }, [mixer, animations, loop]);

  useEffect(() => {
    if (!ready) {
      return;
    }

    if (scene) {
      onInitScene && onInitScene(scene);
    }

    mixer.setTime(0);
    setStarted(true);
  }, [ready, mixer, scene, onInitScene]);

  useFrame((_, delta) => {
    if (started) {
      mixer?.update(delta);
    }
  });

  return (
    <Suspense fallback={null}>
      <group
        scale={state.scale}
        position={state.position}
        rotation={state.rotation}
        dispose={null}
      >
        <primitive object={scene} />
      </group>
    </Suspense>
  );
};
