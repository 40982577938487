import { cn } from '@collection-platform-frontend/shared';
import {
  motion,
  useMotionTemplate,
  useTime,
  useTransform,
} from 'framer-motion';
import Image, { StaticImageData } from 'next/image';
import {
  FC,
  MouseEvent,
  RefObject,
  useCallback,
  useRef,
  useState,
} from 'react';

import sm from './card.module.css';

// 参考：
// https://codepen.io/gayane-gasparyan/pen/jOmaBQK?editors=0100
// https://codepen.io/akella/pen/XWYrRmb?editors=0111
// https://vercel.com/contact/sales?utm_source=next-site&utm_medium=navbar&utm_campaign=next-website
// https://nextjs.org/
// https://github.com/simeydotme/pokemon-cards-css

//モバイルの場合はホバーが難しいので、自動で回転しても良さそう

export type CardProps = {
  classNames?: string;
  itemImage: string | StaticImageData;
  backgroundImg?: string | StaticImageData;
  maskImage?: string | StaticImageData;
  backLightBlurStyle?:
    | 'blur-2xl'
    | 'blur-xl'
    | 'blur-md'
    | 'blur-sm'
    | 'hidden';
  backLightColor?: 'white' | 'rainbow';
  holo?: 'none' | 'cosmos';
  hasFrame?: boolean;
  onClick?: () => void;
};

export const Card: FC<CardProps> = ({
  classNames,
  itemImage,
  maskImage,
  backgroundImg,
  backLightBlurStyle = 'hidden',
  backLightColor = 'white',
  holo = 'none',
  hasFrame,
  onClick,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [cardGlareStyle, setCardGlareStyle] = useState({});
  const [cardHoloStyle, setCardHoloStyle] = useState({});

  const mouseRotationVariants = {
    rotateX: mousePosition.y,
    rotateY: mousePosition.x,
  };

  const time = useTime();
  const rotate = useTransform(time, [0, 10000], [0, 360], { clamp: false });

  const rainbowBg = useMotionTemplate`linear-gradient(
    ${rotate}deg,
    rgba(255, 0, 0, 1) 0%,
    rgba(255, 154, 0, 1) 10%,
    rgba(208, 222, 33, 1) 20%,
    rgba(79, 220, 74, 1) 30%,
    rgba(63, 218, 216, 1) 40%,
    rgba(47, 201, 226, 1) 50%,
    rgba(28, 127, 238, 1) 60%,
    rgba(95, 21, 242, 1) 70%,
    rgba(186, 12, 248, 1) 80%,
    rgba(251, 7, 217, 1) 90%,
    rgba(255, 0, 0, 1) 100%
)`;

  const backLight = backLightColor === 'white' ? '#ffffff' : rainbowBg;

  // Actions
  const onHover = useCallback(
    (event: MouseEvent): void => {
      if (ref.current !== null) {
        const data = getMouseData(event, ref);

        if (data) {
          const { x: rX, y: rY } = getRotation(
            data.CoordinateX,
            data.CoordinateY,
          );
          const { x: bgX, y: bgY } = getBackgroundPosition(
            data.CoordinateX,
            data.CoordinateY,
          );

          const { glare, holo } = getBackgroundStyles(
            data.CoordinateX,
            data.CoordinateY,
            bgX,
            bgY,
          );
          setCardGlareStyle({ background: glare });
          setCardHoloStyle({
            background: holo,
            maskImge: `url(${maskImage})`,
            WebkitMaskImage: `url(${maskImage})`,
          });
          setMousePosition({ x: rX, y: rY });
        }
      }
    },
    [maskImage],
  );

  const onMouseLeave = useCallback(() => {
    setMousePosition({ x: 0, y: 0 });
  }, []);

  return (
    <div
      className={cn(
        sm['card'],
        'aspect-card  w-[280px]  max-w-[400px] select-none sm:w-[350px]',
        classNames,
      )}
      onClick={onClick}
    >
      <div className={cn(sm['card__wrapper'])}>
        <motion.div
          id="back_light"
          animate={mouseRotationVariants}
          className={cn(
            'absolute h-full w-full bg-white will-change-auto',
            backLightBlurStyle,
          )}
          style={{
            willChange: 'filter',
            backgroundImage: backLight,
          }}
        />
        <motion.div
          id="card_3d"
          ref={ref}
          animate={mouseRotationVariants}
          className={cn(
            'relative flex flex-col border border-gray-500/50',
            sm['card__3d'],
          )}
          onMouseMove={(event) => onHover(event)}
          onMouseLeave={onMouseLeave}
        >
          {/* Background Image */}
          {backgroundImg && (
            <Image
              className="absolute inset-0 w-full h-full"
              src={backgroundImg}
              fill
              style={{ objectFit: 'cover' }}
              alt=""
              sizes="400"
              quality={50}
              priority
            />
          )}

          {/* Item Image */}
          <Image
            className="absolute inset-0 w-full h-full"
            src={itemImage}
            fill
            style={{ objectFit: 'cover' }}
            alt=""
            sizes="400"
            priority
          />
          {holo === 'cosmos' && (
            <div
              className={cn('overflow-hidden ', sm['card__holo'], {
                'm-[14px]': hasFrame,
              })}
              style={cardHoloStyle}
            />
          )}
          <div className={cn(sm['card__glare'])} style={cardGlareStyle} />
        </motion.div>
      </div>
    </div>
  );
};

export type MouseDataType = {
  mouseX: number;
  mouseY: number;
  refClientWidth: number;
  refClientHeight: number;
  refBoundingClientRect: DOMRect;
  CoordinateX: number;
  CoordinateY: number;
};

export const getMouseData = (
  event: MouseEvent,
  ref: RefObject<HTMLDivElement>,
): MouseDataType | undefined => {
  if (ref.current == null) {
    return;
  }
  const mouseX = event.clientX;
  const mouseY = event.clientY;
  const w = ref.current.clientWidth;
  const h = ref.current.clientHeight;
  const b = ref.current.getBoundingClientRect();

  // Coordinate
  const X = (mouseX - b.left) / w;
  const Y = (mouseY - b.top) / h;

  return {
    mouseX: mouseX,
    mouseY: mouseY,
    refClientWidth: w,
    refClientHeight: h,
    refBoundingClientRect: b,
    CoordinateX: X,
    CoordinateY: Y,
  };
};

export const getRotation = (CoordinateX: number, CoordinateY: number) => {
  const rX = -(CoordinateX - 0.5) * 20;
  const rY = (CoordinateY - 0.5) * 20;
  return {
    x: rX,
    y: rY,
  };
};

export const getBackgroundPosition = (
  CoordinateX: number,
  CoordinateY: number,
) => {
  const bgX = 40 + 20 * CoordinateX;
  const bgY = 40 + 20 * CoordinateY;
  return {
    x: bgX,
    y: bgY,
  };
};

export const getHoloStyle = ({
  x,
  y,
  bgX,
  bgY,
  step = 5,
}: {
  x: number;
  y: number;
  bgX: number;
  bgY: number;
  step?: number;
}) => {
  const pattern_1 = `url("/assets/card_demo_assets/pattern/cosmos-top-trans.png") center center / cover,
  url("/assets/card_demo_assets/pattern/cosmos-middle.png") center center / cover,
  url("/assets/card_demo_assets/pattern/cosmos-bottom.png") center center / cover,
  repeating-linear-gradient(
    0deg,
    hsl(2, 100%, 73%) calc(${step}% * 1),
    hsl(53, 100%, 69%) calc(${step}% * 2),
    hsl(93, 100%, 69%) calc(${step}% * 3),
    hsl(176, 100%, 76%) calc(${step}% * 4),
    hsl(228, 100%, 74%) calc(${step}% * 5),
    hsl(283, 100%, 73%) calc(${step}% * 6),
    hsl(2, 100%, 73%) calc(${step}% * 7)
  ) 0% ${bgY}% / 200% 700%,
  repeating-linear-gradient(
    128deg,
    #0e152e 0%,
    hsl(180, 10%, 60%) 3.8%,
    hsl(180, 29%, 66%) 4.5%,
    hsl(180, 10%, 60%) 5.2%,
    #0e152e 10%,
    #0e152e 12%
  ) ${bgX + bgY}% ${bgY}% / 200% 300%,
  radial-gradient(
    farthest-corner circle at ${x * 100}% ${y * 100}%,
    hsla(0, 0%, 0%, 0.1) 12%,
    hsla(0, 0%, 0%, 0.15) 20%,
    hsla(0, 0%, 0%, 0.25) 120%
  ) ${bgX}% ${bgY}% / 200% 100%`;
  return pattern_1;
};

export const getGlareStyle = (x: number, y: number) => {
  return `radial-gradient(
      farthest-corner circle at ${x * 100}% ${y * 100}%,
      hsla(0, 0%, 100%, 0.8) 10%,
      hsla(0, 0%, 100%, 0.65) 20%,
      hsla(0, 0%, 0%, 0.5) 90%
    `;
};

export const getBackgroundStyles = (
  x: number,
  y: number,
  bgX: number,
  bgY: number,
  maskImg?: string,
) => {
  return {
    glare: getGlareStyle(x, y),
    holo: getHoloStyle({ x, y, bgX, bgY }),
  };
};
