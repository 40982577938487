import { cn } from '@collection-platform-frontend/shared';
import NextImage, {
  ImageProps as NextImageProps,
  StaticImageData,
} from 'next/legacy/image';
import { FC, useState } from 'react';

import { Skeleton, SkeletonProps } from '../skeleton/skeleton';

export type ImageProps = Omit<NextImageProps, 'src'> &
  SkeletonProps & {
    src?: string | StaticImageData | null;
    imageClassName?: string;
  };

export const Image: FC<ImageProps> = ({
  className,
  imageClassName,
  skeleton,
  skeletonClassName,
  onLoadingComplete,
  src,
  ...rest
}) => {
  const [isReady, setIsReady] = useState<boolean>();

  const _onLoadingComplete = (result: {
    naturalWidth: number;
    naturalHeight: number;
  }) => {
    setIsReady(true);
    onLoadingComplete && onLoadingComplete(result);
  };

  return (
    <div className={cn('relative', className)}>
      <Skeleton
        skeleton={skeleton || !isReady}
        skeletonClassName={skeletonClassName}
      >
        {src ? (
          <NextImage
            className={imageClassName}
            onLoadingComplete={_onLoadingComplete}
            {...rest}
            src={src}
          />
        ) : (
          <div className="w-full h-full" />
        )}
      </Skeleton>
    </div>
  );
};
