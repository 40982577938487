import { cn } from '@collection-platform-frontend/shared';
import { Dialog, Transition } from '@headlessui/react';
import { FC, Fragment, ReactNode } from 'react';

export type ModalProps = {
  children?: ReactNode;
  contentClassName?: string;
  backgroundClassName?: string;
  show: boolean;
  isShow?: (value: boolean) => void;
};

export const Modal: FC<ModalProps> = ({
  children = <div />,
  contentClassName,
  backgroundClassName,
  show,
  isShow = () => undefined,
}) => {
  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-popup" onClose={isShow}>
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className={cn('fixed inset-0 bg-black/80', backgroundClassName)}
            />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-6 overflow-hidden text-center ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={cn(
                    'flex items-center justify-center w-full text-center transition-all transform',
                    contentClassName,
                  )}
                >
                  {children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
