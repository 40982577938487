export const Loading = () => {
  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen">
      <div className="inline-block w-full max-w-xs overflow-hidden text-left align-middle transition-all transform">
        <div className="flex flex-col items-center p-14 rounded-3xl gap-y-2 min-w-[300px]">
          <div className="flex justify-center">
            <div className="w-20 h-20 border-4 rounded-full animate-spin border-t-transparent"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
