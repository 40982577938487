import { FC, useEffect, useRef } from 'react';
import {
  Group,
  Mesh,
  MeshStandardMaterial,
  RepeatWrapping,
  VideoTexture,
} from 'three';

export type VideoConfig = {
  src: string;
  targetMesh: string;
  muted: boolean;
};

type GltfExtendProps = {
  modelScene?: Group;
  videoConfig?: VideoConfig;
};

export const GltfExtend: FC<GltfExtendProps> = ({
  modelScene,
  videoConfig,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const video = videoRef?.current;

  useEffect(() => {
    if (!video || !videoConfig) {
      return;
    }

    video.muted = videoConfig.muted;
    video.play();
  }, [video, videoConfig]);

  useEffect(() => {
    if (!modelScene || !video || !videoConfig) {
      return;
    }

    const videoTexture = new VideoTexture(videoRef.current);
    videoTexture.wrapS = RepeatWrapping;

    const pcObject = modelScene.getObjectByName(videoConfig.targetMesh) as Mesh;
    const pcMaterial = pcObject.material as MeshStandardMaterial;
    if (pcMaterial) {
      pcMaterial.emissiveIntensity = 0.5;
      pcMaterial.emissiveMap = videoTexture;
    }
  }, [video, videoConfig, modelScene]);

  return (
    <>
      {videoConfig && (
        <video
          ref={videoRef}
          hidden
          width="100%"
          height="100%"
          autoPlay
          loop
          playsInline
          muted={videoConfig.muted}
          src={videoConfig.src}
        />
      )}
      <div />
    </>
  );
};
