import { cn } from '@collection-platform-frontend/shared';
import {
  ChangeEvent,
  FC,
  forwardRef,
  InputHTMLAttributes,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { isAndroid, isIOS } from 'react-device-detect';

import { Typography } from '../typography/typography';

type InputContaierProps = {
  className?: string;
  errorMessage?: string;
};

const InputContainer: FC<PropsWithChildren<InputContaierProps>> = ({
  children,
  className,
  errorMessage,
}) => {
  return (
    <div className="flex flex-col">
      <div
        className={cn(
          'box-border flex items-center w-full px-4 bg-wallet-light-base dark:bg-wallet-base border rounded-2xl border-wallet-primary focus-within:border-wallet-inactive dark:border-wallet-inactive dark:focus-within:border-wallet-primary font-noto',
          {
            'border-wallet-light-error focus-within:border-wallet-light-error dark:border-wallet-error dark:focus-within:border-wallet-error':
              !!errorMessage,
          },
          className,
        )}
      >
        <div className="w-full">{children}</div>
      </div>
      {errorMessage && (
        <Typography
          variant="caption"
          className="pt-1 text-wallet-light-error dark:text-wallet-error"
        >
          {errorMessage}
        </Typography>
      )}
    </div>
  );
};

export type InputProps = InputHTMLAttributes<HTMLInputElement> &
  InputContaierProps & {
    forceUppercase?: boolean;
    onChangeValue?: (value: string) => void;
  };

export const Input: FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
  function Input(props, ref) {
    const {
      className,
      errorMessage,
      forceUppercase,
      onChange,
      onChangeValue,
      ...rest
    } = props;

    const _onChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
          onChange(event);
        }

        if (onChangeValue) {
          let value = event.currentTarget.value;
          if (forceUppercase) {
            value = value.toUpperCase();
            event.currentTarget.value = value;
          }

          onChangeValue(value);
        }
      },
      [onChange, forceUppercase, onChangeValue],
    );

    return (
      <InputContainer className={className} errorMessage={errorMessage}>
        <input
          ref={ref}
          type="text"
          className="w-full text-wallet-light-primary dark:text-wallet-primary rounded-full px-[1px] py-3 bg-wallet-light-base dark:bg-wallet-base shadow-none border-none focus:!outline-none focus:!ring-0 focus:!shadow-none"
          {...rest}
          onChange={_onChange}
        />
      </InputContainer>
    );
  },
);

export type MaskInputProps = InputProps & {
  unit?: number;
  formatChar?: string;
  filterCharsIsWhitelist?: boolean;
};

export const MaskInput: FC<MaskInputProps> = ({
  className,
  errorMessage,
  unit = 4,
  forceUppercase = false,
  formatChar = '-',
  filterCharsIsWhitelist = false,
  onChangeValue,
  defaultValue,
  ...rest
}) => {
  const ref = useRef<HTMLInputElement>(null);

  const format = useCallback(
    (inputRef: HTMLInputElement, isDeleteText: boolean) => {
      const selectionStart = inputRef.selectionStart;

      let value = inputRef.value;
      if (forceUppercase) {
        value = value.toUpperCase();
      }

      value = value
        .split('')
        .filter((currChar) => {
          if (filterCharsIsWhitelist) {
            return [formatChar].includes(currChar);
          }

          return ![formatChar].includes(currChar);
        })
        .map((v, i) => {
          return i !== 0 && i % unit === 0 ? formatChar + v : v;
        })
        .join('');

      inputRef.value = value;

      const addedFormatChar = value.length - 1 === selectionStart;
      if (isDeleteText) {
        inputRef.setSelectionRange(selectionStart, selectionStart);
      } else if (addedFormatChar) {
        inputRef.setSelectionRange(value.length, value.length);
      } else {
        inputRef.setSelectionRange(selectionStart, selectionStart);
      }

      onChangeValue && onChangeValue(value);
    },
    [forceUppercase, formatChar, filterCharsIsWhitelist, unit, onChangeValue],
  );

  useEffect(() => {
    const inputRef = ref.current;
    if (!inputRef) {
      return;
    }

    if (defaultValue && forceUppercase) {
      format(inputRef, false);
    }
  }, [defaultValue, forceUppercase, format]);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const inputRef = ref.current;
      if (!inputRef) {
        return;
      }

      const ne = event?.nativeEvent as InputEvent;
      const inputType = ne?.inputType;
      if (
        // iOSでinputTypeが以下の時、複数回onChangeが走るのを回避
        isIOS &&
        (inputType === 'insertCompositionText' ||
          inputType === 'deleteCompositionText')
      ) {
        return;
      }

      const isDeleteText = inputType.includes('delete');
      if (isAndroid) {
        setTimeout(() => format(inputRef, isDeleteText), 100);
      } else {
        format(inputRef, isDeleteText);
      }
    },
    [ref, format],
  );

  return (
    <InputContainer className={className} errorMessage={errorMessage}>
      <input
        ref={ref}
        className="w-full text-wallet-light-primary dark:text-wallet-primary rounded-full px-[1px] py-3 bg-wallet-light-base dark:bg-wallet-base shadow-none border-none focus:!outline-none focus:!ring-0 focus:!shadow-none"
        {...rest}
        defaultValue={defaultValue}
        type="text"
        inputMode="text"
        onChange={onChange}
      />
    </InputContainer>
  );
};
