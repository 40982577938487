import { FC, SVGAttributes } from 'react';

//<defs><style>.cls-1{fill:#fff;}.cls-2{fill:#06c755;}</style></defs>
export const LineLogo: FC<SVGAttributes<HTMLOrSVGElement>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 320" {...props}>
      <g id="LINE_LOGO">
        <rect fill="#06c755" width="320" height="320" rx="72.14" ry="72.14" />
        <path
          fill="#fff"
          d="m266.66,144.92c0-47.74-47.86-86.58-106.69-86.58s-106.69,38.84-106.69,86.58c0,42.8,37.96,78.64,89.23,85.42,3.47.75,8.2,2.29,9.4,5.26,1.08,2.7.7,6.92.35,9.65,0,0-1.25,7.53-1.52,9.13-.47,2.7-2.14,10.55,9.24,5.75,11.39-4.8,61.44-36.18,83.82-61.94h0c15.46-16.96,22.87-34.16,22.87-53.27Z"
        />
        <path
          fill="#06c755"
          d="m231.16,172.49h-29.97c-1.13,0-2.04-.91-2.04-2.04v-.03h0v-46.48h0v-.05c0-1.13.91-2.04,2.04-2.04h29.97c1.12,0,2.04.92,2.04,2.04v7.57c0,1.13-.91,2.04-2.04,2.04h-20.37v7.86h20.37c1.12,0,2.04.92,2.04,2.04v7.57c0,1.13-.91,2.04-2.04,2.04h-20.37v7.86h20.37c1.12,0,2.04.92,2.04,2.04v7.57c0,1.13-.91,2.04-2.04,2.04Z"
        />
        <path
          fill="#06c755"
          d="m120.29,172.49c1.12,0,2.04-.91,2.04-2.04v-7.57c0-1.12-.92-2.04-2.04-2.04h-20.37v-36.96c0-1.12-.92-2.04-2.04-2.04h-7.57c-1.13,0-2.04.91-2.04,2.04v46.53h0v.04c0,1.13.91,2.04,2.04,2.04h29.97Z"
        />
        <rect
          fill="#06c755"
          x="128.73"
          y="121.85"
          width="11.64"
          height="50.64"
          rx="2.04"
          ry="2.04"
        />
        <path
          fill="#06c755"
          d="m189.84,121.85h-7.57c-1.13,0-2.04.91-2.04,2.04v27.65l-21.3-28.77c-.05-.07-.11-.14-.16-.21,0,0,0,0-.01-.01-.04-.04-.08-.09-.12-.13-.01-.01-.03-.02-.04-.03-.04-.03-.07-.06-.11-.09-.02-.01-.04-.03-.06-.04-.03-.03-.07-.05-.11-.07-.02-.01-.04-.03-.06-.04-.04-.02-.07-.04-.11-.06-.02-.01-.04-.02-.06-.03-.04-.02-.08-.04-.12-.05-.02,0-.04-.02-.07-.02-.04-.01-.08-.03-.12-.04-.02,0-.05-.01-.07-.02-.04,0-.08-.02-.12-.03-.03,0-.06,0-.09-.01-.04,0-.07-.01-.11-.01-.04,0-.07,0-.11,0-.02,0-.05,0-.07,0h-7.52c-1.12,0-2.04.91-2.04,2.04v46.56c0,1.12.91,2.04,2.04,2.04h7.57c1.13,0,2.04-.91,2.04-2.04v-27.65l21.33,28.81c.15.21.33.38.53.51,0,0,.02.01.02.02.04.03.08.05.13.08.02.01.04.02.06.03.03.02.07.03.1.05.03.02.07.03.1.04.02,0,.04.02.06.02.05.02.09.03.14.04,0,0,.02,0,.03,0,.17.04.35.07.53.07h7.52c1.12,0,2.04-.91,2.04-2.04v-46.56c0-1.12-.91-2.04-2.04-2.04Z"
        />
      </g>
    </svg>
  );
};
