import { cn } from '@collection-platform-frontend/shared';
import { FC, PropsWithChildren } from 'react';

import { Typography } from '../typography/typography';

export type TagProps = { classname?: string };

export const Tag: FC<PropsWithChildren<TagProps>> = ({
  children,
  classname,
}) => {
  return (
    <div
      className={cn(
        'px-6 py-3 bg-white border rounded-full border-wallet-light-inactive/50 hover:opacity-50 w-fit',
        classname,
      )}
    >
      <Typography variant="h2" className="hidden sm:block">
        {children}
      </Typography>
      <Typography variant="h3" className="block sm:hidden">
        {children}
      </Typography>
    </div>
  );
};
