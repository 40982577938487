import { FC } from 'react';

import { Typography } from '..';
import { Article } from './article';

export type ArticleSectionProps = {
  title?: string;
  description?: string;
  contents?: (string | any)[];
};

export const ArticleSection: FC<ArticleSectionProps> = ({
  title,
  description,
  contents,
}) => {
  return (
    <section className="py-2">
      {title && (
        <Typography variant="h3" className="pb-4">
          {title}
        </Typography>
      )}
      {description && (
        <Typography variant="body" className="pb-4">
          <span dangerouslySetInnerHTML={{ __html: description }} />
        </Typography>
      )}
      {contents && <Article contents={contents} />}
    </section>
  );
};
