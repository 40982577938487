import { cn } from '@collection-platform-frontend/shared';
import { ButtonHTMLAttributes, FC } from 'react';

import { Typography } from '../typography/typography';

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  primary?: boolean;
  slim?: boolean;
  visible?: boolean;
  loading?: boolean;
};

export const Button: FC<ButtonProps> = ({
  children,
  primary,
  slim,
  loading,
  visible = true,
  disabled,
  className,
  ...rest
}) => {
  return (
    <button
      className={cn(
        'px-4 py-3 min-w-[90px] box-border w-full hover:opacity-70 flex items-center justify-center',
        {
          'bg-wallet-light-primary text-wallet-light-base dark:bg-wallet-primary dark:text-wallet-base':
            primary,
          'bg-wallet-light-base text-wallet-light-primary border-wallet-light-primary dark:bg-wallet-base dark:text-wallet-primary border dark:border-wallet-primary ':
            !primary,
          'bg-wallet-light-inactive dark:bg-wallet-inactive text-wallet-light-base dark:text-wallet-primary hover:opacity-100':
            disabled || loading,
          'opacity-0 pointer-events-none': !visible,
        },
        slim ? 'h-10 rounded-xl' : 'h-16 rounded-3xl',
        className,
      )}
      {...rest}
      disabled={disabled || loading}
    >
      {loading ? (
        <div className="flex justify-center">
          <div className="w-5 h-5 border-4 border-white rounded-full animate-spin border-t-transparent"></div>
        </div>
      ) : (
        <Typography>{children}</Typography>
      )}
    </button>
  );
};

export default Button;
